<template>
  <v-list
    dense
    nav
    class="menu_formulariosTrabalhos"
    transition="scroll-x-reverse-transition"
  >
    <v-list-item-group color="info">
      <v-list-item @click="voltarPai('dashboard')">
        <v-list-item-action>
          <v-icon>mdi-keyboard-return</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Voltar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-2"></v-divider>

      <div v-for="(menu, index) in menus" :key="index">
        <v-list-item
          :to="{ path: menu.path }"
          v-if="menu.type === 'single'"
          :disabled="menu.disabled"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="menu.type === 'group'"
          :group="menu.path"
          no-action
          :prepend-icon="menu.icon"
          :disabled="menu.disabled"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </template>

          <div v-for="(child, index) in menu.children" :key="index">
            <v-list-item
              v-if="child.type === 'single'"
              :to="{ path: child.path }"
              :disabled="child.disabled"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ child.name }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list-group>
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "MenuFormulariosTrabalhos",

  data() {
    return {};
  },

  computed: {
    menus() {
      return [
        {
          type: "single",
          name: "Novo Serviço",
          path: "/gestor/atividades/lista-atividades",
          icon: "mdi-toolbox-outline",
          disabled: false,
        },
        {
          type: "group",
          name: "Briefings",
          path: "/gestor/formulario-trabalho/briefings",
          icon: "mdi-briefcase-edit-outline",
          disabled: false,
          children: [
            {
              type: "single",
              name: "Briefing",
              path: "/gestor/formulario-trabalho/briefings/lista-briefing",
              icon: "mdi-circle-medium",
              disabled: true,
            },
          ],
        },
        {
          type: "group",
          name: "Planejamentos",
          path: "/gestor/formulario-trabalho/planejamentos",
          icon: "mdi-notebook-outline",
          disabled: false,
        },
        {
          type: "group",
          name: "Mídias Sociais",
          path: "/gestor/formulario-trabalho/midias-sociais",
          icon: "mdi-facebook",
          disabled: false,
          children: [
            {
              type: "single",
              name: "Calendário Editorial Mídias Sociais",
              path:
                "/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais",
              icon: "mdi-circle-medium",
              disabled: false,
            },
            {
              type: "single",
              name: "Cronograma Mídias Sociais",
              path:
                "/gestor/formulario-trabalho/midias-sociais/cronograma-midias-sociais",
              icon: "mdi-circle-medium",
              disabled: false,
            },
          ],
        },
        {
          type: "group",
          name: "WEB",
          path: "/gestor/formulario-trabalho/web",
          icon: "mdi-facebook-workplace",
          disabled: false,
        },
      ];
    },
  },

  methods: {
    voltarPai(menu) {
      this.$store.commit("UPDATE_MENUDINAMICO", menu);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_formulariosTrabalhos ::v-deep .v-list-item {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.menu_formulariosTrabalhos ::v-deep .v-list-item .v-list-item__title {
  font-size: 0.9rem !important;
  font-weight: 500;
  line-height: 1rem;
}
</style>
